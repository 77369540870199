$(document).ready( function () {

});


function showTaxonomies (){
    if ($(window).width() >= 768) {
        $("nav#taxonomies .collapse").addClass("show");
    }
    else {
        $("nav#taxonomies .collapse").removeClass("show");
    }
}


$(window).resize(function(){
    showTaxonomies();
});


document.addEventListener("turbolinks:load", function() {
    showTaxonomies();
});